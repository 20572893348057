<template>
<div>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
  <v-layout wrap row xs12>
    <v-menu ref="dateFrom"
            lazy
            :close-on-content-click="false"
            v-model="dateFrom"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-right="40"
            min-width="290px"
            dark
            :return-value.sync="from">
        <v-text-field slot="activator"
                      label="С: "
                      v-model="from"
                      prepend-icon="mdi-calendar"
                      single-line
                      readonly></v-text-field>
        <v-date-picker locale="ru-ru" :first-day-of-week="1" v-model="from" @input="$refs.dateFrom.save(from)"></v-date-picker>
    </v-menu>
    <v-menu ref="dateTo"
            lazy
            :close-on-content-click="false"
            v-model="dateTo"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-right="40"
            min-width="290px"
            dark
            :return-value.sync="to">
        <v-text-field slot="activator"
                      label="По: "
                      v-model="to"
                      prepend-icon="mdi-calendar"
                      single-line
                      readonly></v-text-field>
        <v-date-picker locale="ru-ru" :first-day-of-week="1" v-model="to" @input="$refs.dateTo.save(to)"></v-date-picker>
    </v-menu>
    <v-btn color="info" @click="updateStats">Показать</v-btn>
    <v-btn color="info" @click="onReset">Очистить</v-btn>
  </v-layout>
  </v-container>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
  
    <v-layout wrap>
      <v-flex
        md12
      >
        <material-card
          color="green"
          title="Продажи"
          text="Статистика продаж по торговым автоматам за период"
        >
          <v-data-table
            :headers="headers"
            :items="items"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ getDeviceName(item.deviceId) }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.price / 100 }}</td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>

    </v-layout>
  </v-container>
</div>
</template>

<script>
export default {
  data () {
    return {
      dateFrom: null,
      from: null,
      dateTo: null,
      to: null,
      devices: [],
      selectedDeviceId: null,
      orderStats: null,
      
      headers: [
        {
          sortable: true,
          text: 'Название',
          value: 'name'
        },
        {
          sortable: true,
          text: 'Количество',
          value: 'amount'
        },
        {
          sortable: true,
          text: 'Сумма, ₽',
          value: 'price',
          
        }
      ],
      items: [ ],
    }
  },
  mounted () {
    let today = new Date(Date.now());
    this.from = today.toISOString().substr(0, 10)
    today.setDate(today.getDate() + 1)
    this.to = today.toISOString().substr(0, 10)
    this.getStats()
    this.getDevices()
  },
  // watch: {
  // },
  methods: {
    updateStats(){
      this.getStats()
    },
    onReset() {
        let today = new Date(Date.now());
        this.from = today.toISOString().substr(0, 10)
        today.setDate(today.getDate() + 1)
        this.to = today.toISOString().substr(0, 10)
        this.updateStats();
    },
    getStats () {
      var options = {
          params: {
              from: this.from,
              to: this.to,
          }
      };
      this.$http.get('/Statistics/events/devices', options)
        .then(
          response => {
            this.items = response.data
          }
        )
    },
    getDeviceName(deviceId){
      if (deviceId && this.devices){
        const device = this.devices.find(x=>x.id == deviceId);
        if (device != null) return device.name;
      }
      return deviceId;
    },
    complete (index) {
      this.list[index] = !this.list[index]
    },
    getDevices() {
      this.$http.get('/vendingmachines')
        .then(response => {
          this.devices = response.data
          this.devices.unshift({'id': null, name: 'Все'})
        })
    }
  }
}
</script>
